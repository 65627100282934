<template>
  <div class="navbar">
    <i class="icon-menu"  @click="handleOpenSidebar()"></i>
    <div class="navbar-right">
      <el-avatar :size="40" :src="avatar" >
        <img :src="require('@/assets/image/ava.png')" alt=""/>
      </el-avatar>
      <el-dropdown trigger="click" placement="bottom-end" @command="handleCommand">
        <span class="el-dropdown-link">
          <el-icon size="16" color="#3a3a3a" class="m-l-10 cursor-point"><CaretBottom /></el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="setting">Setting</el-dropdown-item>
            <el-dropdown-item command="logout">Logout</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
  <Layer :layer="layer" @confirm="logout()">
    <div class="font-size-17 font-weight-bold">Log out confirmation</div>
    <div class="font-size-12 m-t-15">Are you sure you want to log out?</div>
  </Layer>
</template>

<script setup>
import { useStore } from "vuex";
import {computed, ref, watch} from "vue";
import { CaretBottom } from '@element-plus/icons-vue'
import router from "@/router";
import {getUserInfo} from '@/api/user'
import Layer from "@/components/Layer/index.vue";

const layer = ref({
  show: false,
  title: '',
  showButton: true
})
const fileUrl = process.env.VUE_APP_SHOW_FILE
const store = useStore()
const emit = defineEmits(['toggleSidebar'])
const avatar = computed(() => store.getters['userInfo']?.logo && store.getters['userInfo']?.logo.indexOf('http') > -1 ? store.getters['userInfo'].logo : (fileUrl + store.getters['userInfo'].logo))

const handleOpenSidebar = () => {
  emit('toggleSidebar', true)
}
const handleCommand = (e) => {
  if(e === 'setting') {
    router.push({path: '/settings', query: {}})
  } else if(e === 'logout') {
    layer.value.show = true;
    layer.value.confirmTxt = 'Log out';
    layer.value.width = 380;
  }
}
const logout = () => {
  store.dispatch('logout')
}
const getUser = () => {
  getUserInfo().then((res) => {
    if(res && res.code === 0) {
      store.commit('SET_USERINFO', res.data)
    }
  })
}
getUser()
watch(router.currentRoute, () => {
  getUser()
})
</script>

<style scoped lang="scss">
.navbar {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  border-bottom: 1px solid #DBDBDB;
  i.icon-menu {
    font-size: 22px;
    display: inline-block;
    cursor: pointer;
    color: var(--system-base-color);
    &:hover {
      opacity: 0.7;
    }
  }
  .navbar-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>